import React from 'react';
import { Card, Grid } from 'semantic-ui-react';
import 'react-table/react-table.css';
import Header from '../../app/layout/ui/header';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../app/layout/ui/icons';
const Dashboard = () => {
	return (
		<div className="page-wrapper">
			<Header title="Dashboard" />

			<div className="page-padding">
				<Grid>
					{/***********************************  FIRST ROW *************************************************/}

					<Grid.Row columns={3}>
						<Grid.Column>
							<NavLink to="/kegs/create">
								<Card fluid>
									<Card.Content>
										<Card.Header textAlign="center">
											<FontAwesomeIcon icon={icons.faDatabase} /> Create New
											Kegs
										</Card.Header>
									</Card.Content>
								</Card>
							</NavLink>
						</Grid.Column>
						<Grid.Column>
							<NavLink to="/companies/create">
								<Card fluid>
									<Card.Content>
										<Card.Header textAlign="center">
											<FontAwesomeIcon icon={icons.faBuilding} /> Create New
											Brewery
										</Card.Header>
									</Card.Content>
								</Card>
							</NavLink>
						</Grid.Column>
						<Grid.Column>
							<NavLink to="/users">
								<Card fluid>
									<Card.Content>
										<Card.Header textAlign="center">
											<FontAwesomeIcon icon={icons.faUserEdit} /> Manage Users
										</Card.Header>
									</Card.Content>
								</Card>
							</NavLink>
						</Grid.Column>
					</Grid.Row>

					{/*******************************  SECOND ROW **********************************************/}
					<Grid.Row columns={3}>
						<Grid.Column>
							<NavLink to="/kegs/settings">
								<Card fluid>
									<Card.Content>
										<Card.Header textAlign="center">
											<FontAwesomeIcon icon={icons.faCogs} /> Kegs Settings
										</Card.Header>
									</Card.Content>
								</Card>
							</NavLink>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		</div>
	);
};

export default Dashboard;
