import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { formatUserData } from './formatting';

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
	const firebase = useFirebase();
	const [users, setUsers] = useState();
	const { claims } = useAuth();
	useEffect(() => {
		return claims?.companyLink
			? firebase.users().onSnapshot(
					(snap) => setUsers(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');	
	}, [claims, firebase]);

	const createUser = async (user) => {
		return await firebase.users().add(
			formatUserData({
				...user,
				dateCreated: new Date()
			})
		);
	};
	const updateUser = async (user, id) => {
		return await firebase
			.users()
			.doc(id)
			.update(
				formatUserData({
					...user,
					dateUpdated: new Date()
				})
			);
	};

	const archiveUser = async (isArchive, id) => {
		return await firebase
			.users()
			.doc(id)
			.update(
				formatUserData({
					isArchive: !isArchive,
					dateUpdated: new Date()
				})
			);
	};

	return (
		<UsersContext.Provider
			value={{
				users,
				createUser,
				updateUser,
				archiveUser
			}}
		>
			{children}
		</UsersContext.Provider>
	);
};

export const UsersConsumer = UsersContext.Consumer;

export const useUsers = () => useContext(UsersContext);
