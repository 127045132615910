import React, { Component } from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
	state = {
		hasError: false
	};

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.log(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="error-boundary-wrapper">
					<div className="error-boundary-box">
						<h1>
							Something went wrong. Please try login in again at:{' '}
							<a href="https://app.kegtracker.co.za">
								https://app.kegtracker.co.za
							</a>
						</h1>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
