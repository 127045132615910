import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo
} from 'react';
/* import { useAuthContext } from "../auth"; */
import { transformCollectionSnap } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth, useUser } from '../auth';
import {
	formatKegData,
	formatKegToBeCreated,
	formatKegToBeUpdated
} from './formatting';

const KegsContext = createContext({});
const addZeroes = (num) => {
	while (num.length < 4) num = '0' + num;
	return num;
};
export const KegsProvider = ({ children }) => {
	const firebase = useFirebase();

	const { claims } = useAuth();
	const user = useUser();
	const [kegs, setKegs] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase.kegs().onSnapshot(
					(snap) => setKegs(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('no compId');
	}, [claims, firebase]);

	const breweries = useMemo(() => {
		if (kegs) {
			return kegs.reduce((breweries, k) => {
				return k.companyLink
					? breweries.includes(k.companyLink)
						? breweries
						: [...breweries, k.companyLink]
					: breweries;
			}, []);
		}
	}, [kegs]);

	const breweryKegs = useMemo(() => {
		let breweryKegs = [];
		if (breweries) {
			breweries.forEach((b) => {
				breweryKegs = [
					...breweryKegs,
					{
						totalStock: kegs.filter((k) => k.companyLink === b).length,
						usedStock: kegs.filter(
							(k) =>
								k.companyLink === b &&
								(k.type === 'keg' || k.type === 'gas' || k.type === 'fridge')
						).length,
						kegs: kegs.filter((k) => k.companyLink === b && k.type === 'keg')
							.length,
						fridges: kegs.filter(
							(k) => k.companyLink === b && k.type === 'fridge'
						).length,
						gas: kegs.filter((k) => k.companyLink === b && k.type === 'gas')
							.length,
						notSet: kegs.filter((k) => k.companyLink === b && !k.type).length,
						brewery: b,
						breweryName: b && b.substring(b.indexOf('__') + 2)
					}
				];
			});
		}

		return breweryKegs;
	}, [kegs, breweries]);

	const updateKeg = async (keg) => {
		return await firebase
			.kegs()
			.update(formatKegData(formatKegToBeUpdated(user, keg)));
	};

	// useEffect(() => {
	// 	if (kegs) {
	// 		const baseID = 'BI';
	// 		const firstKegNum = parseFloat('1000');
	// 		const latestID = kegs
	// 			? kegs.sort((a, b) => (a.id < b.id ? 1 : -1))[0].id
	// 			: `${baseID}${firstKegNum}`;
	// 		const lastIDNum = parseInt(latestID.substring(6, 2));
	// 		const kegBaseid = latestID.substring(0, 2);
	// 		console.log(kegBaseid, 'last id num');
	// 	}
	// }, [kegs]);

	const createKeg = async (keg, numOfKegToCreate, baseCode, lastNumber) => {

		console.log("Creating Kegs...");
		////// THIS IS NOW PRECALCULATED
		// const baseID = 'BI';
		// const newBaseID = 'KT';
		// const firstKegNum = parseFloat('0000');
		// // GET LATEST ID DYNAMICALLY

		// const latestID = kegs
		// 	? kegs.sort((a, b) => (a.id < b.id ? 1 : -1))[0].id
		// 	: `${baseID}${firstKegNum}`;
		// //  const latestID = 'BI9999';
		// let lastIDNum;

		// console.log("latestID", latestID);

		// if (latestID === 'BI9999') {
		// 	lastIDNum = firstKegNum + 1;
		// } else {
		// 	lastIDNum = parseInt(latestID.substring(6, 2));
		// }

		
		// const kegBaseID = latestID.substring(0, 2);
		let batch = firebase.db.batch();
		let startKegNum = '';
		let endKegNum = '';
		let allocatedCompany = '';
		let kegNumbers = '';
		let generatedID;
		let generatedIDs = [];
		// CREATE ALL KEGS
		for (let i = 1; i <= numOfKegToCreate; i++) {
			// if (generatedIDs[generatedIDs.length - 1] === 'BI9999') {
			// 	generatedIDs.push('KT0001');
			// 	generatedID = `KT0001`;
			// } else {
			// 	const lastNum = parseInt(
			// 		generatedIDs[generatedIDs.length - 1].substring(6, 2)
			// 	);
			// 	const newNum = lastNum + 1;

			// 	if (generatedIDs[generatedIDs.length - 1].substring(0, 2) === 'BI') {
			// 		generatedID = `${baseID}${newNum}`;
			// 		generatedIDs.push(generatedID);
			// 	} else {
			// 		const formatedDigit = addZeroes(newNum.toString());
			// 		generatedID = `${newBaseID}${formatedDigit}`;
			// 		generatedIDs.push(generatedID);
			// 	}
			// }

			generatedID = `${baseCode}${(lastNumber + i)
				.toString()
				.padStart(4, '0')}`

			generatedIDs.push(generatedID);
			console.log(generatedID);

			const newKeg = formatKegData(
				formatKegToBeCreated(user, { ...keg, label: generatedID })
			);
			// set not archived by default
			newKeg.isArchive = false;

			let kegDocRef = await firebase.kegs().doc(generatedID);
			await batch.set(kegDocRef, newKeg);

			if (i === 1) startKegNum = generatedID;
			if (i === parseFloat(numOfKegToCreate)) endKegNum = generatedID;

			if (newKeg.companyObj) {
				allocatedCompany = ` for ${newKeg.companyObj.name}`;
			} else {
				allocatedCompany = ` with no owner - set the owner with a batch update`;
			}
		}
		// if (startKegNum === endKegNum) {
		if (numOfKegToCreate == 1) {
			kegNumbers = generatedIDs[0];
		} else {
			kegNumbers = `${generatedIDs[0]} to ${generatedIDs[generatedIDs.length - 1]}`;
		}
		return await batch.commit().then((_) => ({ allocatedCompany, kegNumbers }));
	};

	return (
		<KegsContext.Provider
			value={{
				kegs,
				breweryKegs,
				updateKeg,
				createKeg
			}}
		>
			{children}
		</KegsContext.Provider>
	);
};

export const KegsConsumer = KegsContext.Consumer;

export const useKegs = () => useContext(KegsContext);
