// FontAwesome Library
import { library } from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-solid-svg-icons';
import {
	faTruck,
	faTint,
	faPlusCircle,
	faInboxIn,
	faLifeRing,
	faSignOutAlt,
	faTachometer,
	faBeer,
	faClipboard,
	faUserEdit,
	faBuilding,
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faDatabase,
	faUserCircle,
	faInfoCircle,
	faClipboardList,
	faSearch,
	faHistory,
	faClipboardCheck,
	faArrowAltToRight,
	faMapMarkerAlt,
	faPencil,
	faAddressBook,
	faMapMarkerEdit,
	faDollyFlatbed,
	faUsers,
	faInventory,
	faDewpoint,
	faRefrigerator,
	faCompactDisc,
	faTrash,
	faCogs
} from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
library.add(
	faTruck,
	faTint,
	faPlusCircle,
	faInboxIn,
	faLifeRing,
	faSignOutAlt,
	faTachometer,
	faBeer,
	faClipboard,
	faUserEdit,
	faBuilding,
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faDatabase,
	faUserCircle,
	faInfoCircle,
	faClipboardList,
	faSearch,
	faHistory,
	faClipboardCheck,
	faArrowAltToRight,
	faMapMarkerAlt,
	faPencil,
	faAddressBook,
	faMapMarkerEdit,
	faDollyFlatbed,
	faUsers,
	faInventory,
	faDewpoint,
	faRefrigerator,
	faBeer,
	faCompactDisc,
	faTrash,
	faPlus,
	faCogs
);
export {
	faTruck,
	faTint,
	faPlusCircle,
	faInboxIn,
	faLifeRing,
	faSignOutAlt,
	faTachometer,
	faBeer,
	faClipboard,
	faUserEdit,
	faBuilding,
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faDatabase,
	faUserCircle,
	faInfoCircle,
	faClipboardList,
	faClipboardCheck,
	faSearch,
	faHistory,
	faArrowAltToRight,
	faMapMarkerAlt,
	faPencil,
	faAddressBook,
	faMapMarkerEdit,
	faDollyFlatbed,
	faUsers,
	faInventory,
	faDewpoint,
	faRefrigerator,
	faCompactDisc,
	faTrash,
	faPlus,
	faCogs
};
