import React from 'react';
import { KegsProvider } from '../kegs';
import { CompaniesProvider } from '../companies';
import { UsersProvider } from '../users';

const DataProvider = ({ children }) => {
	return (
		<UsersProvider>
			<CompaniesProvider>
				<KegsProvider>{children}</KegsProvider>
			</CompaniesProvider>
		</UsersProvider>
	);
};

export default DataProvider;
