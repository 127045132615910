import React from 'react';

function NavIcon({ open }) {
	const classes = ['nav-icon'];
	if (open) {
		classes.push('open');
	}
	return (
		<div className={classes.join(' ')}>
			<span />
			<span />
			<span />
			<span />
		</div>
	);
}

export default NavIcon;
