import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavigationItem.css';

const NavigationItem = (props) => (
	<li className="navigation-item">
		<NavLink to={props.link} exact={props.exact} onClick={props.toggle}>
			<FontAwesomeIcon icon={props.icon} />
			{props.children}
		</NavLink>
	</li>
);

export default NavigationItem;
