import { getObjFromLink } from '../helpers';

export const formatKegData = (keg) => {
	const currentTime = new Date();

	const kegFields = {
		...keg,
		dateUpdated: currentTime
	};
	// Format Company into an Object to store
	if (keg.companyLink) {
		kegFields.companyObj = getObjFromLink(keg.companyLink);
	}
	// Format Warehouse into an Object to store
	if (keg.locationLink) {
		kegFields.locationObj = getObjFromLink(keg.locationLink);
	}
	// Format Brand into an Object to store
	if (keg.brandLink) {
		kegFields.brandObj = getObjFromLink(keg.brandLink);
	}
	// Format Brand into an Object to store
	if (keg.lastFillBrandLink) {
		kegFields.lastFillBrandObj = getObjFromLink(keg.lastFillBrandLink);
	}
	// Format Client into an Object to store
	if (keg.clientLink) {
		kegFields.clientObj = getObjFromLink(keg.clientLink);
	}

	if (keg.partnerLink) {
		kegFields.partnerObj = getObjFromLink(keg.partnerLink);
	}

	if (keg.coords) {
		delete kegFields.coords;
		kegFields.gps = { lat: keg.coords.latitude, lng: keg.coords.longitude };
	}

	return kegFields;
};

export const formatKegToBeEmptied = (user) => {
	const currentTime = new Date();
	return {
		dateEmptied: currentTime,

		kegStatus: 'empty',
		isFilled: false,
		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		emptiedBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		}
	};
};

export const formatKegToBeCreated = (user, values) => {
	return {
		kegStatus: 'new',
		isNew: true,
		isFilled: false,
		createdBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		...values
	};
};

export const formatKegToBeUpdated = (user, values) => {
	return {
		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		...values
	};
};

export const formatKegForOnboard = (values, user) => {
	const currentTime = new Date();

	return {
		kegStatus: 'empty',
		locationStatus: 'in_warehouse__own',
		isFilled: false,
		isNew: false,
		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		onboardedBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		dateOnboarded: currentTime,
		...values
	};
};

export const formatKegForDispatch = (user, client, warehouse) => {
	const currentTime = new Date();

	let keg = {
		locationLink: `${warehouse.id}__${warehouse.name}`,
		dateDispatched: currentTime,

		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		dispatchedBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		}
	};

	if (client.type === 'client') {
		keg.clientLink = `${client.id}__${client.name}`;
		keg.kegStatus = 'dispatched';
		keg.locationStatus = 'dispatched__client';
	}

	return keg;
};

export const formatKegForReceive = (user, warehouse) => {
	const currentTime = new Date();
	return {
		locationLink: `${warehouse.id}__${warehouse.name}`,
		dateReceived: currentTime,

		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		receivedBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		}
	};
};

export const formatPartnerKeg = (keg, client, firestoreDelete) => {
	let formattedKeg = {};
	if (keg.partnerLink) {
		formattedKeg.partnerObj = firestoreDelete;
		formattedKeg.partnerLink = firestoreDelete;
		formattedKeg.responsibleParty = 'owner';
		formattedKeg.locationStatus = 'receive__partner';
	} else {
		formattedKeg.partnerLink = `${client.id}__${client.name}`;
		formattedKeg.responsibleParty = 'partner';
		formattedKeg.locationStatus = 'dispatched__partner';
	}
	return formattedKeg;
};

export const formatKegforFill = ({ user, brandLink, locationLink, batch }) => {
	const currentTime = new Date();

	const keg = {
		locationLink,
		brandLink,
		lastFillBrandLink: brandLink,
		dateFilled: currentTime,
		kegStatus: 'warehouse',
		isFilled: true,
		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		filledBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		}
	};
	if (batch) {
		keg.batchObj = {
			id: batch.id,
			batchNo: batch.batchNo,
			bestBefore: batch.bestBefore
		};
	}

	return keg;
};

export const formatKegForTransfer = ({ locationLink, user }) => {
	const currentTime = new Date();
	const keg = {
		locationLink,

		workedOnBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		transferedBy: {
			name: user.displayName,
			email: user.email,
			id: user.id
		},
		dateTransferred: currentTime
	};
	return keg;
};

export const formatBarcode = (code) => {
	const capitalisedCode = code ? code.toUpperCase() : '';

	const hasBI = capitalisedCode.substring(0, 2) === 'BI';

	return hasBI ? capitalisedCode : `BI${capitalisedCode}`;
};

export const formatIssueKegForArchive = (user) => ({
	dateUpdated: new Date(),
	workedOnBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	},
	archivedBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	},
	isArchive: true
});

export const formatIssueKeg = (values, user) => ({
	...values,
	dateUpdated: new Date(),
	locationStatus: 'in_warehouse__issue',
	workedOnBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	},
	kegStatus: 'issue',
	dateMarkedAsIssue: new Date(),
	markedAsIssueBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	}
});

export const formatFixKeg = (values, user) => ({
	...values,
	dateFixed: new Date(),
	dateUpdated: new Date(),
	locationStatus: 'in_warehouse__own',
	isFilled: false,
	workedOnBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	},
	fixedBy: {
		name: user.displayName,
		email: user.email,
		id: user.id
	},
	kegStatus: 'empty'
});
