import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './SideDrawer.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import release from '../../../config/release.json';
import NavIcon from '../navIcon';

const mapState = (state) => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile
	};
};

class SideDrawer extends Component {
	state = {
		showMobileMenu: false
	};
	mobileMenuToggleHandler = () => {
		this.setState((prevState) => {
			return { showMobileMenu: !prevState.showMobileMenu };
		});
	};
	render() {
		const { profile } = this.props;

		return (
			<div className="side-drawer">
				<div className="side-drawer-header">
					<div className="logo">
						<Link to="/">
							<img
								src="/assets/images/keg-tracker-logo.svg"
								alt="Keg Tracker"
							/>
						</Link>
					</div>
					<div
						className="mobile-nav-trigger"
						onClick={this.mobileMenuToggleHandler}
					>
						<NavIcon open={this.state.showMobileMenu} />
					</div>
				</div>
				<NavigationItems
					profile={profile}
					visible={this.state.showMobileMenu}
					toggle={this.mobileMenuToggleHandler}
				/>
				<div className="version">Version {release.versionAdmin}</div>
				<div className="product-of">
					<h3>A product of</h3>
					<a
						href="https://beverageinsights.co"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/beverage-insights-logo.svg"
							alt="Beverage Insights"
						/>
					</a>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(mapState)(SideDrawer));
