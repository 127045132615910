import React from 'react';
import { Form } from 'semantic-ui-react';

const AuthTextInput = ({
	input,
	width,
	type,
	placeholder,
	label,
	className,
	meta: { touched, error }
}) => {
	return (
		<Form.Field
			error={touched && !!error}
			width={width}
			className="single-input"
		>
			{label && <label>{label}</label>}
			<input {...input} placeholder={placeholder} type={type} />
		</Form.Field>
	);
};

export default AuthTextInput;
