import React, { createContext, useContext, useState, useEffect } from 'react';
import { getObjFromLink, transformCollectionSnap } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { formatCompanyData } from './formatting';

const CompaniesContext = createContext({});

export const CompaniesProvider = ({ children }) => {
	const firebase = useFirebase();
	const { claims } = useAuth();

	const [companies, setCompanies] = useState();

	useEffect(() => {
		return claims?.companyLink
			? firebase.companies().onSnapshot(
					(snap) => setCompanies(transformCollectionSnap(snap)),
					(err) => console.log(err)
			  )
			: () => console.log('not admin');
	}, [claims, firebase]);

	const createCompany = async (company) => {
		const updatedCompany = formatCompanyData(company);
		firebase.companies().add(updatedCompany);
	};

	const archiveCompany = async (isArchive, id) => {
		return await firebase
			.company(getObjFromLink(claims.companyLink).id)
			.update({
				isArchive: !isArchive,
				dateUpdated: new Date()
			});
	};

	return (
		<CompaniesContext.Provider
			value={{ companies, archiveCompany, createCompany }}
		>
			{children}
		</CompaniesContext.Provider>
	);
};

export const CompaniesConsumer = CompaniesContext.Consumer;

export const useCompanies = () => useContext(CompaniesContext);
