import React, { useState, useEffect, useContext, createContext } from 'react';
import { useFirebase } from '../Firebase';

const AuthContext = createContext();
const UserContext = createContext();

export const AuthProvider = ({ children }) => {
	const firebase = useFirebase();

	const [authState, setAuthState] = useState({
		isLoading: true,
		user: undefined,
		userID: undefined,
		claims: undefined
	});
	const [userState, setUserState] = useState();

	const handleLoginRedirect = async (authUser) => {
		if (!authUser && window.location.pathname !== '/') {
			window.location.replace('/');
		}
	};

	const handleDashboardRedirect = async (claims) => {
		if (claims && !claims.firstLogin && window.location.pathname === '/') {
			window.location.replace('/dashboard');
		}
	};

	useEffect(() => {
		firebase.checkUserAuth(async (authUser) => {
			handleLoginRedirect(authUser);
			if (authUser) {
				const claims = await firebase.claims();
				firebase.user(authUser.uid).update({ lastLogin: new Date() });
				console.log('logged in');
				setAuthState((prev) => ({
					...prev,
					user: authUser,
					userId: authUser.uid,
					claims: claims.companyLink ? claims : null
				}));
				handleDashboardRedirect(claims);
			} else {
				setAuthState((prev) => ({
					...prev,
					user: undefined,
					userID: undefined,
					claims: undefined
				}));
			}
		});
	}, [firebase]);

	const handleUserSnapshot = (snap) => {
		setUserState((prev) => ({ ...prev, ...snap.data(), id: snap.id }));
	};

	useEffect(() => {
		console.log(authState?.user?.uid);
		const unsubscribe = authState?.user?.uid
			? firebase
					.user(authState.user.uid)
					.onSnapshot(handleUserSnapshot, (error) => {
						console.log(error);
					})
			: () => console.log('not logged in');
		return () => unsubscribe();
	}, [authState, firebase]);

	//if no claims set on user
	useEffect(() => {
		if (authState.claims === null && userState !== undefined) {
			console.log('no claims');
			const claims = {
				isAdmin: userState.role === 'admin',
				companyLink: userState.companyLink,
				isClient: userState.role === 'client',
				isWarehouse: userState.role === 'warehouse',
				isDistributor: userState.role == 'distributor'
			};

			setAuthState((prev) => ({ ...prev, claims }));
		}
	}, [authState, userState, firebase]);

	const updateCompany = (companyLink) => {
		setAuthState((prev) => ({
			...prev,
			claims: { ...prev.claims, companyLink }
		}));
	};
	const clearData = () => {
		setAuthState((prev) => ({
			...prev,
			user: undefined,
			userID: undefined,
			claims: undefined
		}));
	};

	return (
		<UserContext.Provider value={userState}>
			<AuthContext.Provider value={{ ...authState, updateCompany, clearData }}>
				{children}
			</AuthContext.Provider>
		</UserContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
export const useUser = () => useContext(UserContext);
