import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginPage from '../../features/auth/login';
import { AuthProvider } from '../context/auth';
import { FirebaseProvider } from '../context/Firebase';
import Router from './Router';

class App extends Component {
	componentDidMount() {
		// const requestStart = window.performance.timing.requestStart;
		// const domInteractive = window.performance.timing.domInteractive;
		// const tti = domInteractive - requestStart;
		// console.log('TTI: ', tti);
	}
	render() {
		return (
			<FirebaseProvider>
				<AuthProvider>
					<Switch>
						<Route exact path="/" component={LoginPage} />
						<Route path="/(.+)" render={() => <Router />} />
					</Switch>
				</AuthProvider>
			</FirebaseProvider>
		);
	}
}

export default App;
