import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// ----------------------------------------------------------------------------

// FIREBASE SET-UP
export const firebaseEnv = process.env.REACT_APP_ENV;
const firebaseConfig = {
	appName: 'Keg Tracker',
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DB_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_ID
};

// ----------------------------------------------------------------------------
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

export default firebase;
