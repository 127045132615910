import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { NavLink, withRouter } from 'react-router-dom';
import './PageHeader.css';
import { Dropdown, Menu } from 'semantic-ui-react';
import { logout, clearData } from '../../../../features/auth/authActions';
import styled from 'styled-components';

const mapState = (state, ownProps) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile,
	claims: state.claims
});
const actions = {
	logout,
	clearData
};

const TopMenu = styled(Menu)`
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	.item {
		color: #fff !important;
	}
	.item::before {
		width: 0px;
		background: transparent !important;
	}
`;

class PageHeader extends Component {
	logoutHandler = async () => {
		const { logout, history, clearData } = this.props;
		await clearData();
		logout();
		setTimeout(() => history.replace('/'), 500);
	};
	render() {
		const { auth, title } = this.props;
		let txt = auth.email;

		return (
			<header className="app-header">
				<TopMenu>
					<Menu.Item>
						<h1 className="page-title">{title}</h1>
					</Menu.Item>

					<Menu.Menu position="right">
						<Dropdown item text={txt}>
							<Dropdown.Menu>
								<Dropdown.Item
									as={NavLink}
									to={`/settings`}
									text="Settings"
									icon="settings"
								/>
								<Dropdown.Item
									icon="power"
									text="Logout"
									onClick={this.logoutHandler}
								/>
							</Dropdown.Menu>
						</Dropdown>
					</Menu.Menu>
				</TopMenu>
			</header>
		);
	}
}

export default withRouter(
	withFirebase(
		connect(
			mapState,
			actions
		)(PageHeader)
	)
);
