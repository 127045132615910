import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import './login.css';
import {
	combineValidators,
	composeValidators,
	hasLengthGreaterThan,
	isRequired,
	createValidator
} from 'revalidate';
import AuthTextInput from '../../app/common/form/AuthTextInput';
import release from '../../app/config/release.json';
import { useFirebase } from '../../app/context/Firebase';

const mapState = (state) => ({
	formStatus: state.form.loginForm
});

const isValidEmail = createValidator(
	(message) => (value) => {
		if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)) {
			return message;
		}
	},
	'Invalid email address'
);

const validate = combineValidators({
	email: composeValidators(
		isRequired({ message: 'Email Address is required' }),
		isValidEmail
	)(),
	password: composeValidators(
		isRequired({ message: 'Password is required' }),
		hasLengthGreaterThan(5)({
			message: 'Password needs to be 6 characters or longer'
		})
	)()
});

const LoginPage = ({
	handleSubmit,
	invalid,
	submitting,
	pristine,
	formStatus
}) => {
	const [isLogin, setIsLogin] = useState(true);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const { login, sendForgotPasswordEmail } = useFirebase();
	const doLogin = (credentials) => {
		setLoading(true);
		login(credentials)
			.then((d) => setLoading(false))
			.catch((err) => {
				setError(err.message);
				setLoading(false);
			});
	};

	const resetPassword = (credentials) => {
		setLoading(true);
		sendForgotPasswordEmail(credentials)
			.then((d) => setLoading(false))
			.catch((err) => {
				setError(err.message);
				setLoading(false);
			});
	};

	return (
		<div className="login-page-container">
			<div className="login-page">
				<div className="login-logo">
					<img
						src="/assets/images/keg-tracker-logo.svg"
						alt="Keg Tracker - Powered by Beverage Insights"
					/>
				</div>
				{isLogin ? (
					<div className="login-box">
						<h2>Login</h2>
						{error && <div className="loginErrorMessage">{error}</div>}
						<Form size="large" onSubmit={handleSubmit(doLogin)}>
							<Field
								label="Your Email Address"
								name="email"
								component={AuthTextInput}
								type="text"
								placeholder="name@domain.com"
							/>
							<Field
								label="Your Password"
								name="password"
								component={AuthTextInput}
								type="password"
								placeholder="********"
							/>
							<Button
								loading={loading}
								disabled={invalid || submitting || pristine || loading}
								fluid
								size="large"
								className="button-dark-blue"
							>
								{loading ? 'LOADING...' : 'LOGIN'}
							</Button>
						</Form>
					</div>
				) : (
					<div className="login-box single-input">
						<h2>Forgot password?</h2>
						{error && <div className="loginErrorMessage">{error}</div>}
						{formStatus && !formStatus.submitSucceeded ? (
							<Form size="large" onSubmit={handleSubmit(resetPassword)}>
								<Field
									label="Your Email Address"
									name="email"
									component={AuthTextInput}
									type="text"
									placeholder="name@domain.com"
								/>
								<Button
									loading={loading}
									disabled={invalid || submitting || pristine || loading}
									fluid
									size="large"
									className="button-dark-blue"
								>
									REQUEST NEW PASSWORD
								</Button>
							</Form>
						) : (
							<div className="loginSuccessMessage">
								The email has been sent with password reset instructions.
							</div>
						)}
					</div>
				)}
				<div className="login-box-footer">
					<p
						className="forgot-pass-link"
						onClick={() => setIsLogin((prev) => !prev)}
					>
						{isLogin ? 'Forgot Password?' : '← Back to Login'}
					</p>
					<p>
						&copy;{' '}
						<a
							href="https://bigbrave.digital"
							target="_blank"
							rel="noopener noreferrer"
						>
							BIGBrave&reg;
						</a>{' '}
						and{' '}
						<a
							href="https://beverageinsights.co"
							target="_blank"
							rel="noopener noreferrer"
						>
							Beverage Insights
						</a>
						{' - '} All Rights Reserved
					</p>
					<div className="version login">{release.versionClient}</div>
				</div>
			</div>
		</div>
	);
};

export default connect(mapState)(
	reduxForm({ form: 'loginForm', enableReinitialize: true, validate })(
		LoginPage
	)
);
