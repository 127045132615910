import React, { createContext, useContext } from "react";
import Firebase from "./functions";

const FirebaseContext = createContext({});

export const FirebaseProvider = ({ children }) => (
	<FirebaseContext.Provider value={Firebase}>
		{children}
	</FirebaseContext.Provider>
);

export const FirebaseConsumer = FirebaseContext.Consumer;

export const useFirebase = () => useContext(FirebaseContext);
