export const formatUserData = (user, isNew) => {
	const currentTime = new Date();
	let userFields = {};

	//Remove & Reformat fields
	if (user.phoneMobile) {
		user.phoneMobile = user.phoneMobile.match(/[0-9]/g).join('');
	}
	if (user.phoneWork) {
		user.phoneWork = user.phoneWork.match(/[0-9]/g).join('');
	}

	if (isNew) {
		userFields = {
			...user,
			displayName: user.nameFirst + ' ' + user.nameLast,
			dateCreated: currentTime,
			showWelcome: true
		};
	} else {
		userFields = {
			...user,
			displayName: user.nameFirst + ' ' + user.nameLast,
			dateUpdated: currentTime
		};
	}

	// Format Company into an Object to store
	if (user.companyLink) {
		const sep = user.companyLink.indexOf('__');
		const compId = user.companyLink.substring(0, sep);
		const compName = user.companyLink.substring(sep + 2);

		userFields.companyObj = {
			name: compName,
			id: compId
		};
	}

	if (user.distributorCompaniesLink && user.distributorCompaniesLink.length > 0) {
		userFields.companyObjs = user.distributorCompaniesLink.map(link => {
			const sep = link.indexOf('__');
			const compId = link.substring(0, sep);
			const compName = link.substring(sep + 2);
			return {
				name: compName,
				id: compId,
				link: link,
			};
		});
	}

	return {
		...userFields
	};
};
