import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CheckVersion from '../common/util/CheckVersion';
import SideDrawer from './navigation/SideDrawer/SideDrawer';
import Loadable from 'react-loadable';
import LoadingComponent from './LoadingComponent';
import Dashboard from '../../features/dashboard';

import LoginPage from '../../features/auth/login';
import DataProvider from '../context/data';
import { useAuth } from '../context/auth';

const AsyncKegCreate = Loadable({
	loader: () => import('../../features/kegs/kegCreate'),
	loading: LoadingComponent
});

const AsyncKegManage = Loadable({
	loader: () => import('../../features/kegs/kegManage'),
	loading: LoadingComponent
});

const AsyncUsers = Loadable({
	loader: () => import('../../features/users'),
	loading: LoadingComponent
});

const AsyncSettings = Loadable({
	loader: () => import('../../features/settings'),
	loading: LoadingComponent
});
const AsyncUserDetailedPage = Loadable({
	loader: () =>
		import('../../features/users/UserDetailedPage/UserDetailedPage'),
	loading: LoadingComponent
});
const AsyncCreateUser = Loadable({
	loader: () => import('../../features/users/CreateUser/CreateUser'),
	loading: LoadingComponent
});
const AsyncCreateCompany = Loadable({
	loader: () => import('../../features/companies/CreateCompany/CreateCompany'),
	loading: LoadingComponent
});

const AsyncKegTypes = Loadable({
	loader: () => import('../../features/settings/kegTypes'),
	loading: LoadingComponent
});
export default function Router() {
	const { claims } = useAuth();

	return (
		<DataProvider>
			{!claims?.companyLink ? (
				<LoadingComponent />
			) : (
				<div className="app-container">
					<SideDrawer />
					<div className="page-content">
						<CheckVersion />
						<Switch>
							<Route path="/" exact component={LoginPage} />
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/kegs/create" exact component={AsyncKegCreate} />
							<Route path="/kegs/manage" exact component={AsyncKegManage} />
							<Route path="/kegs/settings" exact component={AsyncKegTypes} />
							<Route path="/settings" component={AsyncSettings} />
							<Route path="/users/create" exact component={AsyncCreateUser} />
							<Route path="/users/:id" component={AsyncUserDetailedPage} />
							<Route path="/users" exact component={AsyncUsers} />
							<Route
								path="/companies/create"
								exact
								component={AsyncCreateCompany}
							/>

							<Redirect to="/" />
						</Switch>
					</div>
				</div>
			)}
		</DataProvider>
	);
}
