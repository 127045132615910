/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import * as icons from '../../ui/icons/';

class NavigationItems extends Component {
	render() {
		const { visible, toggle } = this.props;
		const classes = ['main-nav'];
		if (visible) {
			classes.push('open');
		} else classes.push('closed');
		return (
			<nav className={classes.join(' ')}>
				{process.env.REACT_APP_ENV !== 'production' && (
					<div className="staging-label">{process.env.REACT_APP_ENV}</div>
				)}
				<div className="admin-label">ADMIN APP</div>
				<ul className="navigation-items">
					<div>
						<NavigationItem
							link="/dashboard"
							exact
							icon={icons.faTachometer}
							toggle={toggle}
						>
							Dashboard
						</NavigationItem>
						<NavigationItem
							link="/kegs/create"
							icon={icons.faDatabase}
							toggle={toggle}
						>
							Create New Kegs
						</NavigationItem>
						<NavigationItem
							link="/kegs/manage"
							icon={icons.faDatabase}
							toggle={toggle}
						>
							Manage Kegs
						</NavigationItem>
						<NavigationItem
							link="/companies/create"
							exact
							icon={icons.faBuilding}
							toggle={toggle}
						>
							Create New Brewery
						</NavigationItem>
						<NavigationItem
							link="/users"
							exact
							icon={icons.faUserEdit}
							toggle={toggle}
						>
							Manage Users
						</NavigationItem>
					</div>
				</ul>
			</nav>
		);
	}
}

export default NavigationItems;
