import { createReducer } from '../../app/common/util/reducerUtil';
import {
	BULK_ACTION_START,
	BULK_ACTION_FINISH,
	BULK_ACTION_ERROR
} from './bulkConstants';

const initialState = {
	success: false,
	complete: false,
	msg: 'Please try again...'
};

export const bulkActionStarted = (state) => {
	return {
		...state,
		success: false,
		complete: false
	};
};

export const bulkActionFinished = (state, payload) => {
	return {
		...state,
		success: true,
		complete: true,
		msg: payload.msg
	};
};

export const bulkActionError = (state, payload) => {
	return {
		...state,
		success: false,
		complete: true,
		msg: payload.msg
	};
};

export default createReducer(initialState, {
	[BULK_ACTION_START]: bulkActionStarted,
	[BULK_ACTION_FINISH]: bulkActionFinished,
	[BULK_ACTION_ERROR]: bulkActionError
});
