export const formatCompanyData = (company, isNew) => {
	const currentTime = new Date();
	let companyFields = {};

	companyFields = {
		...company,
		dateUpdated: currentTime
	};

	return {
		...companyFields
	};
};
