import {
	asyncActionStart,
	asyncActionFinish,
	asyncActionError
} from '../../app/async/asyncActions';
import { SubmissionError, reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { actionTypes } from 'redux-firestore';

export const login = (credentials) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch(asyncActionStart());
		const firebase = getFirebase();
		const firestore = getFirestore();
		try {
			await firebase
				.auth()
				.signInWithEmailAndPassword(credentials.email, credentials.password);
			firebase
				.auth()
				.currentUser.getIdTokenResult()
				.then((idTokenResult) => {
					if (idTokenResult.claims && idTokenResult.claims.isAdmin) {
						delete idTokenResult.claims.companyLink;
					} else {
					}
					dispatch({
						type: 'SET_ROLE_AND_COMPANY',
						payload: { ...idTokenResult.claims }
					});
				})
				.catch((error) => {
					console.log(error);
				});
			const uid = firebase.auth().currentUser.uid;
			await firestore
				.collection('users')
				.doc(uid)
				.update({ lastLogin: new Date() });
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
			console.log(error);
			throw new SubmissionError({
				_error: error.message
			});
		}
	};
};

export const setToken = () => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.currentUser.getIdTokenResult()
			.then((idTokenResult) => {
				dispatch({
					type: 'SET_ROLE_AND_COMPANY',
					payload: { ...idTokenResult.claims }
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const changeCompany = (companyLink) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		await dispatch({ type: actionTypes.CLEAR_DATA });
		await dispatch({ type: 'CHANGE_COMPANY', payload: companyLink });
	};
};

export const updatePassword = (creds) => async (
	dispatch,
	getState,
	{ getFirebase }
) => {
	const firebase = getFirebase();
	const user = firebase.auth().currentUser;
	try {
		await user.updatePassword(creds.newPassword1);
		await dispatch(reset('account'));
		toastr.success('Success', 'Your password has been updated');
	} catch (error) {
		throw new SubmissionError({
			_error: error.message
		});
	}
};

export const resetPassword = (data) => async (
	dispatch,
	getState,
	{ getFirebase }
) => {
	const firebase = getFirebase();
	const auth = firebase.auth();
	try {
		await auth.sendPasswordResetEmail(data.email);
		await dispatch(reset('loginForm'));
		// toastr.success('Success', 'Check your email inbox for rest instructions.');
	} catch (error) {
		throw new SubmissionError({
			_error: error.message
		});
	}
};

export const clearData = () => async (dispatch) => {
	try {
		await dispatch({ type: actionTypes.CLEAR_DATA });
		return true;
	} catch (error) {
		throw new SubmissionError({
			_error: error.message
		});
	}
};

export const logout = () => async (dispatch, getState, { getFirebase }) => {
	const firebase = getFirebase();
	try {
		await firebase
			.auth()
			.signOut()
			.then(
				function() {
					console.log('Signed Out');
				},
				function(error) {
					console.error('Sign Out Error', error);
				}
			);
		window.location.reload();
	} catch (error) {
		console.log(error);
	}
};
