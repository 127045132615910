import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import config from './config';

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const firestore = firebase.firestore;

const Firebase = {
	/////////////////////// AUTH ///////////////////////////////
	auth,

	login: ({ email, password }) => {
		return auth.signInWithEmailAndPassword(email, password);
	},
	signOut: () => {
		return auth.signOut();
	},
	checkUserAuth: (user) => {
		return auth.onAuthStateChanged(user);
	},

	claims: async () => {
		return await auth.currentUser.getIdTokenResult().then((idTokenResult) => {
			return idTokenResult.claims;
		});
	},
	updatePassword: (password) => {
		return auth.currentUser.updatePassword(password);
	},

	sendForgotPasswordEmail: ({ email }) => {
		return auth.sendPasswordResetEmail(email);
	},

	/////////////////////// FIRESTORE ///////////////////////////////
	db,
	firestore,
	batch: db.batch,
	//----------------- USERS -----------------

	createNewUser: (userData) => {
		return db
			.collection('users')
			.doc()
			.set(userData);
	},

	createUserWithId: (userData) => {
		return db
			.collection('users')
			.doc(userData.id)
			.set(userData);
	},

	user: (userId) => {
		return db.collection('users').doc(userId);
	},

	users: () => {
		return db.collection('users');
	},

	//----------------KEGS-------------------

	kegs: () => {
		return db.collection('kegs');
	},

	keg: (kegId) => {
		return db.doc(`kegs/${kegId}`);
	},

	getKeg: (kegId) => {
		return db.doc(`kegs/${kegId}`).get();
	},
	// deleteKegs:(id)=>{
	// 	return db.collection('kegs').doc(id).delete()
	// },
	//------------------COMPANIES--------------

	companies: () => {
		return db.collection('companies');
	},

	//------------------COMPANY--------------

	company: (compId) => {
		return db.collection('companies').doc(compId);
	},

	//------------------ADDING VOLUMES OPTIONS--------------

	addVolumeOptions: (volume) => {
		return db.collection('volumeOptions').add({
			key: `${volume}ℓ`,
			text: `${volume}ℓ`,
			value: volume
		});
	},
	//------------------ADDING STYLE OPTIONS--------------

	addStyleOptions: (style) => {
		return db.collection('styleOptions').add({
			key: style,
			text: style,
			value: style
		});
	},
	//------------------ADDING COUPLER OPTIONS--------------

	addCouplerOptions: (coupler) => {
		return db.collection('couplerOptions').add({
			key: coupler,
			text: coupler.toUpperCase(),
			value: coupler
		});
	},
	//------------------ADDING TYPE OPTIONS--------------

	addTypeOptions: (type) => {
		return db.collection('typeOptions').add({
			key: type,
			text: type,
			value: type
		});
	},

	//------------------GETTING VOLUME OPTIONS--------------
	gettingVolumeOptions: () => {
		return db.collection('volumeOptions');
	},

	//------------------GETTING TYPE OPTIONS--------------
	gettingTypeOptions: () => {
		return db.collection('typeOptions');
	},
	//------------------GETTING STYLE OPTIONS--------------
	gettingStyleOptions: () => {
		return db.collection('styleOptions');
	},
	//------------------GETTING COUPLER OPTIONS--------------
	gettingCouplerOptions: () => {
		return db.collection('couplerOptions');
	}
};

export default Firebase;
